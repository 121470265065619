var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group-2",class:{
		active: _vm.isActive,
		disabled: _vm.isDisabled,
		error: _vm.firstError,
		focused: _vm.isFocused,
		'no-left-icon': _vm.leftIcon === false,
		'textarea': _vm.isTextArea,
		[_vm.labelStyle]: _vm.labelStyle,
	}},[(_vm.loading)?[_c('input',{ref:"input",staticClass:"input",attrs:{"type":"text","readonly":""},on:{"focus":_vm.focus,"blur":_vm.blur}}),_vm._m(0),(_vm.leftIcon !== false)?_c('span',{staticClass:"loader-icon icon icon-left"}):_vm._e()]:_c(_vm.component,_vm._g(_vm._b({ref:"input",tag:"component",class:_vm.className,attrs:{"type":_vm.type,"id":_vm.id,"name":_vm.name,"value":_vm.value,"placeholder":_vm.computedPlaceholder,"show-password":_vm.showPassword}},'component',_vm.$attrs,false),_vm.listeners)),(_vm.isPassword && _vm.canTogglePassword)?_c('ion-icon',{staticClass:"icon icon-right cursor-pointer text-gray-400",attrs:{"name":_vm.rightDefaultIcon},on:{"click":function($event){$event.preventDefault();return _vm.togglePassword.apply(null, arguments)}}}):(_vm.isDate)?_c('span',{staticClass:"icon icon-right caret text-gray-400",attrs:{"name":_vm.rightDefaultIcon}}):(_vm.rightIcon)?_c('ion-icon',{staticClass:"icon icon-right cursor-pointer text-gray-400",attrs:{"name":_vm.rightIcon},on:{"click":function($event){$event.preventDefault();return _vm.rightIconClick.apply(null, arguments)}}}):(_vm.rightIconImg)?_c('img',{staticClass:"icon icon-right",attrs:{"src":_vm.rightIconImg,"alt":_vm.rightIconImgAlt}}):_vm._e(),(!_vm.isDateOfBirth && _vm.$slots.default)?_c('label',{staticClass:"label",attrs:{"for":_vm.id}},[_vm._t("default")],2):_vm._e(),(_vm.firstError)?_c('div',{staticClass:"form-group-2-text",class:_vm.textColor},[_vm._v(" "+_vm._s(_vm.firstError)+" ")]):_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-loading"},[_c('div',{staticClass:"dot"}),_c('div',{staticClass:"dot"}),_c('div',{staticClass:"dot"})])
}]

export { render, staticRenderFns }